<template>
  <div class="home">
    <div class="homeTopbg">
      <img src="@/assets/homeTopbg.png" alt="">
    </div>
    <div class="topChange">
      <div :class="car == 1 ? 'subjectOneActive' : 'subjectOne'" @click="() => { car = 1; getnums() }">
        <span> རིག་ཚན་དང་པོ། </span><span style="padding-left: 8px;">科目一</span>
        <div v-if="car == 1" class="line"></div>
      </div>
      <div :class="car == 2 ? 'subjectOneActive' : 'subjectOne'" @click="() => { car = 2; getnums() }">
        <span> རིག་ཚན་བཞི་བ། </span><span style="padding-left: 8px;">科目四</span>
        <div v-if="car == 2" class="line"></div>
      </div>
      <div class="BottmLine"></div>
    </div>
    <div class="homeMain">
      <div class="left">
        <div class="videoSile" @click="gospecial('/videoExplanation')">
          <img src="../assets/image/videosea.png" alt="">
          <p class="zangwen"> བརྙན་ལམ་སློབ་ཁྲིད། </p>
          <p class="china">视频讲解</p>
        </div>
        <div class="videoSile" @click="gospecial('/specialProject')">
        <img src="../assets/image/examinRoom.png" alt="">
        <p class="zangwen"> ཆེད་དོན་སྦྱོང་བརྡར། </p>
        <p class="china">专项练习</p>
      </div>
      </div>
      <div class="center">
        <div class="exam1" @click="qukaoshi(2)">
          <p class="sequential"> གོ་རིམ་སྦྱོང་བརྡར། </p>
          <p class="sequentialZw">顺序练习</p>
        </div>
        <div class="exam2" @click="qukaoshi(1)">
          <p class="sequential"> ཚོད་ལྟའི་རྒྱུགས། </p>
          <p class="sequentialZw">模拟考试</p>
        </div>
      </div>
      <div class="right">
        <div class="videoSile" @click="gospecial('/mastickandCollect')">
          <img src="../assets/image/mistakes.png" alt="">
          <p class="zangwen"> ནོར་བ་སྦྱོང་བརྡར </p>
          <p class="china">错题练习</p>
        </div>
        <div class="videoSile" @click="gotoNext('/examination1')">
          <img src="../assets/image/special.png" alt="">
          <p class="zangwen"> རྒྱུགས་རའི་ལད་མོ། </p>
          <p class="china">考场模拟</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Dialog } from 'vant'
import { countNum, countList, getcacheTopic, countOne, countFour, isSupperVip, getCache } from '@/api/count';
import { vip } from '@/api'
export default {
  data() {
    return {
      code: '',
      car: 1,
      numbers: ''
    }
  },
  created() {
    localStorage.setItem('language', 'anduo')
    this.car = this.$route.query.id || 1
    this.getnums()
  },
  methods: {
    /* 获取题目 */
    async getTopic() {
      try {
        this.message = '加载中....'
        this.forbidClick = true
        const res = await countNum(this.car)
        const listRes = await countList({ pageNum: 1, pageSize: res.data, subject: this.car })
        if (listRes.httpStatus !== 200) {
          throw new Error(`服务器返回状态码异常: ${listRes.httpStatus}`)
        }
        // 使用循环来避免重复代码
        const parseMedia = (mediaType, items) => {
          items.forEach(item => {
            if (!item[mediaType] || item[mediaType].length === 0) {
              item[mediaType] = []
            } else if (item[mediaType][0].indexOf('uid') !== -1) {
              try {
                item[mediaType][0] = JSON.parse(item[mediaType][0])
              } catch (e) {
                console.error(`解析${mediaType}失败:`, e)
              }
            }
          })
        }
        parseMedia('audios', listRes.data.list)
        parseMedia('videos', listRes.data.list)
        listRes.data.list.forEach(item => {
          item.result = []
          item.cutindex = null
          item.trueIndex = null
          item.isSelect = false
        });
        if (listRes.data.list.length == 0) {
          Dialog.alert({
            title: '提示',
            message: '暂无题目,正在升级敬请期待~'
          })
          return
        } else {
          localStorage.setItem('tixing', JSON.stringify(listRes.data.list))
          this.$router.push({
            path: '/exercise',
            query: {
              id: this.car
            }
          })
        }
      } catch (e) {
        console.error(e)
        // 根据实际需求，可能需要向用户显示错误信息
      } finally {
        // 无论成功或失败，最后都释放加载状态
        this.message = ''
        this.forbidClick = false
      }
      // this.$toast.loading({
      //   message: '加载中...',
      //   forbidClick: true,
      // })
      // countNum(this.car).then(res => {
      //   countList({ pageNum: 1, pageSize: res.data, subject: this.car }).then(res => {
      //     if (res.httpStatus === 200) {
      //     (res.data.list).forEach(item => {
      //       if (!item.audios || (item.audios).length === 0) {
      //         item.audios = []
      //       } else if (item.audios[0].indexOf('uid') !== -1) {
      //         item.audios[0] =  JSON.parse(item.audios[0])
      //       }
      //       if (!item.videos || item.videos.length == 0) {
      //         item.videos = []
      //       } else if (item.videos[0].indexOf('uid') !== -1) {
      //         item.videos[0] =  JSON.parse(item.videos[0])
      //       }
      //       if (!item.imgList || item.imgList.length == 0) {
      //         this.imgList = []
      //       } else if (item.imgList[0].indexOf('uid') !== -1) {
      //         item.imgList[0] =  JSON.parse(item.imgList[0])
      //       }
      //       item.result = []
      //       item.cutindex = null
      //       item.trueIndex = null
      //       item.isSelect = false
      //     });
      //     localStorage.setItem('tixing', JSON.stringify(res.data.list))
      //     this.$router.push({
      //       path: '/exercise',
      //       query: {
      //         id: this.car
      //       }
      //     })
      //   } else {
      //   }
      //   })
      // })
    },
    // 获取多少个数量
    getnum() {
      const that = this
      getcacheTopic({subject: that.car}).then(res => {
        if (res.data !== 'null') {
          Dialog.confirm({
            title: '提示',
            message: 'འདྲི་གཞི་སྔོན་མ་ཚར་བའི་མུ་མཐུད་དུ་ཞིབ་བཤེར་བྱེད་དམ།?',
            confirmButtonText: 'གཏན་འཁེལ།',
            cancelButtonText: 'མེད་པར་བཟོ་བ།'
          }).then(() => {
            getCache({subject: that.car}).then(() => {})
            const dataJson = JSON.parse(res.data)
            localStorage.setItem('tixing', dataJson.tixing)
            localStorage.setItem('errorList', dataJson.errorList)
            localStorage.setItem('rightList', dataJson.rightList)
            localStorage.setItem('indexcount', dataJson.indexcount)
            this.$router.push({
              path: '/exercise',
              query: {
                cache: this.car
              }
            })
          }).catch(() => {
            this.getTopic()
            getCache({subject: that.car}).then(() => {})
          })
        } else {
          this.getTopic()
          getCache({subject: that.car}).then(() => {})
        }
      })
      
    }, 
    // 获取数量
    getnums() {
      countNum(this.car).then(res => {
        this.numbers = res.data
      })
    },
    qukaoshi(e) {
      switch (e) {
        case 1:
          localStorage.setItem('isSxulianxi', false)
          this.$router.push({
            path: '/testscore',
            query: {
              id: this.car
            }
          })
          break;
        case 2:
        localStorage.setItem('isSxulianxi', true)
          vip().then(res => {
            localStorage.setItem('vip', res.data)
          })
          this.getnum()
          break;
        default:
          break;
      }
    },
    /* 下面个=四个跳转 */
    gospecial(e) {
      localStorage.setItem('isSxulianxi', false)
      isSupperVip().then(res => {
        if (res.data) {
          if (e === '/examination1') {
            vip().then(res => {
              localStorage.setItem('vip', res.data)
            })
            if (this.car == 1) {
              this.getcount();
            } else {
              this.getfour();
            }
          } else {
            this.$router.push(`${e}?id=${this.car}`)
          }
        } else {
          this.$router.push({
            path: "/pay",
            query: {
              ip: 1
            }
          })
        }
      })
    },
    gotoNext(e) {
      isSupperVip().then(res => {
        if (res.data) {
          this.$router.push(`${e}?id=${this.car}`)
        } else {
          this.$router.push({
            path: "/pay",
            query: {
              ip: 1
            }
          })
        }
      })
    },
    // 获取科一考试题目
    getcount() {
      countOne().then((res) => {
        // this.countList = res.data;
        // this.countForm = this.countList[0];
        if (res.httpStatus === 200) {
          (res.data).forEach(item => {
            if (!item.audios || (item.audios).length === 0) {
              item.audios = []
            } else if (item.audios[0].indexOf('uid') !== -1) {
              item.audios[0] =  JSON.parse(item.audios[0])
            }
            if (!item.videos || item.videos.length == 0) {
              item.videos = []
            } else if (item.videos[0].indexOf('uid') !== -1) {
              item.videos[0] =  JSON.parse(item.videos[0])
            }
            if (!item.imgList || item.imgList.length == 0) {
              this.imgList = []
            } else if (item.imgList[0].indexOf('uid') !== -1) {
              item.imgList[0] =  JSON.parse(item.imgList[0])
            }
            
            item.result = []
            item.cutindex = ''
            item.trueIndex = ''
            item.isSelect = false
          });
          console.log(res.data);
          localStorage.setItem('tixing', JSON.stringify(res.data))
          this.$router.push({
            path: '/examination1',
            query: {
              id: this.car
            }
          })
        } else {
          this.$toast({
            message: `${res.message}`,
            duration: 2000
          })
        }
      });
    },
    // 获取科四考试题目
    getfour() {
      countFour().then((res) => {
        if (res.httpStatus === 200) {
          (res.data).forEach(item => {
            if (!item.audios || (item.audios).length === 0) {
              item.audios = []
            } else if (item.audios[0].indexOf('uid') !== -1) {
              item.audios[0] =  JSON.parse(item.audios[0])
            }
            if (!item.videos || item.videos.length == 0) {
              item.videos = []
            } else if (item.videos[0].indexOf('uid') !== -1) {
              item.videos[0] =  JSON.parse(item.videos[0])
            }
            if (!item.imgList || item.imgList.length == 0) {
              this.imgList = []
            } else if (item.imgList[0].indexOf('uid') !== -1) {
              item.imgList[0] =  JSON.parse(item.imgList[0])
            }
            item.result = []
            item.cutindex = ''
            item.trueIndex = ''
            item.isSelect = false
          });
          localStorage.setItem('tixing', JSON.stringify(res.data))
          this.$router.push({
            path: '/examination1',
            query: {
              id: this.car
            }
          })
        } else {
          this.$toast({
            message: `${res.message}`,
            duration: 2000
          })
        }
      });
    },
  }
}
</script>

<style scoped lang="scss">
.home{
  width: 100%;
  height: calc(100vh - 70px);
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fff;
  .homeTopbg{
    width: 100%;
    height: 240px;
    img{
      width: 100%;
      height: 240px;
    }
  }
  .topChange{
    width: 100%;
    height: 69px;
    margin-top: -51px;
    border-radius: 20px 20px 0 0;
    background: #fff;
    position: relative;
    display: flex;
    .subjectOne{
      width: 187.5px;
      height: 69px;
      text-align: center;
      font-weight: 500;
      font-size: 14px;
      color: #666666;
      line-height: 69px;
    }
    .subjectOneActive{
      width: 187.5px;
      height: 69px;
      text-align: center;
      font-weight: 600;
      font-size: 18px;
      color: #333333;
      line-height: 69px;
      position: relative;
      .line{
        width: 55px;
        height: 3px;
        background: #1677FF;
        border-radius: 2px;
        position: absolute;
        bottom: -1.5px;
        left: 50%;
        margin-left: -27.5px;
        z-index: 9;
      }
    }
    .BottmLine{
      width: 351px;
      height: 1px;
      border-bottom: 1px solid rgba(51,51,51,0.2);
      position: absolute;
      bottom: -1px;
      left: 12px;
    }
  }
  .homeMain{
    width: 100%;
    height: 100%;
    display: flex;
    .zangwen{
      text-align: center;
      font-size: 16px;
      color: #333333;
      line-height: 20px;
    }
    .china{
      text-align: center;
      font-weight: 400;
      font-size: 14px;
      color: #666666;
      line-height: 24px;
    }
    .left{
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      .videoSile{
        width: 100px;
        margin-top: 52px;
        display: flex;
        flex-direction: column;
        align-items: center;
        img{
          width: 48px;
          height: 48px;
        }
      }
    }
    .center{
      width: 120px;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: start;
      justify-content: flex-start;
      .sequential{
        text-align: center;
        font-size: 18px;
        color: #FFFFFF;
        line-height: 28px;
      }
      .sequentialZw{
        text-align: center;
        font-weight: 400;
        font-size: 14px;
        color: #FFFFFF;
        line-height: 20px;
      }
      .exam1{
        width: 120px;
        height: 120px;
        margin-top: 36px;
        background: url('../assets/image/examine.png') no-repeat;
        background-size: cover;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
      .exam2{
        width: 120px;
        height: 120px;
        margin-top: 24px;
        background: url('../assets/image/sequential.png') no-repeat;
        background-size: cover;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
    }
    .right{
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      .videoSile{
        width: 100px;
        margin-top: 52px;
        display: flex;
        flex-direction: column;
        align-items: center;
        img{
          width: 48px;
          height: 48px;
        }
      }
    }
  }
}
</style>
